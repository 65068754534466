import React from 'react';
import PromotionsTemplate from '../../../templates/promotions';
import Leaderboard from '../../../components/Leaderboard';
import { goldLeaderboardPromotionData } from '../../../data/promotions';
import config from '../../../config';

const goldLeaderboardPage = ({ location, pageContext }) => (
    <PromotionsTemplate
        pageContext={{ ...pageContext, ...goldLeaderboardPromotionData }}
        location={location}
        component={<Leaderboard id={goldLeaderboardPromotionData.id} selectedTable="step1" leaderboardUrl={config.leaderboard.goldLeaderboardUrl} catalogUrl={config.leaderboard.goldLeaderboardCatalogueUrl} />}
    /> 
);

export default goldLeaderboardPage;
